<template>
  <div>
    <div class="iq-card p-1">
      <div class="row">
        <div class="col-md-12 my-1">
          <select class="form-control"  v-model="filter.project" @change="getAchievedSalesReport">
            <option selected disabled value="">select Project...</option>
            <option v-for="(i, key) in projects" :key="key" :value="i.id">
              <span>{{i.en_title}}</span>
            </option>
          </select>
        </div>
        <div class="col-md-12 my-1">
          <date-range-picker
              class="w-100"
              ref="picker"
              opens=false
              :timePicker=false
              :timePicker24Hour=false
              :showWeekNumbers=true
              :showDropdowns=true
              format='mm/dd/yyyy'
              direction="center"
              :autoApply=true
              v-model="dateRange"
              :linkedCalendars=true
              customRangeLabel= 'Custom Range'
              @update="getAchievedSalesReport"
          >
          </date-range-picker>
        </div>
        <div class="col-md-12 my-1">
          <v-select v-model="filter.callStatusId"
                    multiple
                    placeholder="Select status"
                    name="status_id" id="status_id"
                    :options="call_Status"
                    label="title"
                    :reduce="option => option.id"
                    @input="getAchievedSalesReport"/>
        </div>
        <div class="col-md-12 d-flex justify-content-center align-items-center" v-if="filter.project ||
        filter.callStatusId.length > 0">
          <span class="text-danger cursor-pointer" @click="resetFilter()">Clear All</span>
        </div>
      </div>
    </div>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">Reserved</h4>
      </template>
      <template v-slot:headerAction>
        <h5>Total : {{total}}</h5>
      </template>
      <template v-slot:body>
        <div v-if="loadTable" class="text-center">
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </div>
        <b-table v-else striped responsive :fields="columns" :items="salesReport" class="mb-0 table-borderless">
          <template v-slot:cell(project)="data">
            <span :style={color:data.item.project.color}>{{data.item.project.en_title}}</span>
          </template>
          <template v-slot:cell(created_at)="data">
            <span>{{timeConvert(data.item.created_at)}}</span>
          </template>
          <template v-slot:cell(lead.phone)="data1">
            <p class="holdSelection" v-if="data1.item.id==showPhone">{{data1.item.lead.phone}}</p>
            <b-button v-else variant=" iq-bg-success mr-1 mb-1" size="sm" :id="data1.item.id" @click="showPhone = data1.item.id" ><i class="las la-mobile"></i>Show Phone</b-button>
          </template>
        </b-table>
        <b-pagination v-model="currentPage"
                      class="mt-3"
                      :total-rows="total"
                      align="right"
                      :per-page="per_page"
                      aria-controls="my-table"
                      @input="getAchievedSalesReport()"
        ></b-pagination>
      </template>
    </iq-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import { mapGetters } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
  name: 'salesLeadsReserved',
  data () {
    return {
      showPhone: '',
      projects: [],
      salesReport: [],
      loadTable: true,
      currentPage: 1,
      per_page: 10,
      total: '',
      call_Status: [],
      dateRange: {
        startDate: this.$route.query.startDate || '',
        endDate: this.$route.query.endDate || ''
      },
      filter: {
        project: this.$route.query.project || '',
        userId: '',
        callStatusId: []
      },
      columns: [
        { label: 'Lead Name', key: 'lead.name' },
        { label: 'Call Status', key: 'call_status.title' },
        { label: 'Phone', key: 'lead.phone' },
        { label: 'Created At', key: 'created_at' },
        { label: 'Project', key: 'project' }
      ]
    }
  },
  methods: {
    resetFilter () {
      this.filter = {
        project: '',
        callStatusId: []
      }
      this.getAchievedSalesReport()
    },
    getAchievedSalesReport () {
      if (this.$route.query.user) {
        this.loadTable = true
        this.$store.dispatch('getLeadsSalesReport', {
          status: this.filter.callStatusId,
          pageNumber: this.currentPage,
          userId: this.$route.query.user
            ? this.$route.query.user : '',
          projectId: this.filter.project,
          dateFrom: this.dateRange.startDate ? new
          Date(this.dateRange.startDate).toJSON().slice(0, 10) : '',
          dateTo: this.dateRange.endDate ? new
          Date(this.dateRange.endDate).toJSON().slice(0, 10) : ''
        }).then(res => {
          this.salesReport = res.data.data.leads.data
          this.currentPage = res.data.data.leads.current_page
          this.per_page = res.data.data.leads.per_page
          this.total = res.data.data.total
          this.loadTable = false
        })
      } else {
        this.$router.push({ name: 'salesReportDetails' })
      }
    }
  },
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  mounted () {
    core.index()
  },
  created () {
    this.$store.dispatch('getInfoToCreateLead').then((res) => {
      this.call_Status = res.data.data.call_Status
    })
    this.$store.dispatch('getInfoToCreateLead').then((res) => {
      this.projects = res.data.data.projects
    })
    this.getAchievedSalesReport()
  },
  components: {
    DateRangePicker
  }
}
</script>
<style>

</style>
